import { cx } from "@linaria/core";
import { unstable_Combobox as Combobox, unstable_ComboboxOption as ComboboxOption, unstable_ComboboxPopover as ComboboxPopover, unstable_useComboboxState as useComboboxState } from "reakit/Combobox";
import { BaseHTMLAttributes, ForwardedRef, forwardRef, memo, MouseEvent, type ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { cssIsActive } from "@/styles/utils/Utils";
import { CloseModeType, FieldVariantsPropsType } from "@/types";
import { Loader } from "@/ui/Loaders/ComponentUiLoader/Loader";
import { cssPopoverAngle } from "@/ui/Popover/StyledPopover";
import { SelectedHint } from "@/ui/Select/SelectedHint";
import { cssSelectSmall, cssSelectSmaller, SelectedIcon, StyledButtonContainer, StyledIconSelect, StyledOptionContainer, StyledSelect, StyledSelectedTitle, StyledSelectInputContainer, StyledSelectInputWrapper, StyledSelectListContainer } from "./StyledSelect";
import { Button } from "../Button/Button";
import { Placeholder, StyledFieldWrapper } from "../Field/StyledField";
import { Icon, IconNameType } from "../Icon";
import { ErrorMessageField, Typography } from "../Typography/Typography";
type VariantProps = "small" | "default" | "smaller";
type LayoutPropsType = {
  isHint?: boolean;
};
interface SelectItemsType {
  icon?: IconNameType;
  value: string;
  name: string;
  layout?: ReactNode | ((props: LayoutPropsType) => ReactNode);
  disabled?: boolean;
}
export interface SelectPropsType {
  items: SelectItemsType[];
  baseId?: string;
  ariaLabel?: string;
  isIcon?: boolean;
  variant: VariantProps;
  onSelectValue?: (value: string) => void;
  stateSelect?: string;
  isFetching?: boolean;
  buttonAddedItem?: ReactNode;
  iconSelect?: IconNameType;
  staticPlaceholder?: string;
  isVisibleLayout?: boolean;
  isVisibleSelectedHint?: boolean;
  required?: boolean;
  classNameWrapper?: string;
  withAnimatingLabel?: boolean;
  isVisible?: boolean;
  onClose?: () => void;
  closeMode?: CloseModeType;
}
export const renderElement = ({
  item = {
    value: "",
    name: ""
  },
  isVisibleLayout = false
}: {
  item?: SelectItemsType;
  isVisibleLayout: boolean;
}) => {
  const {
    layout,
    name
  } = item ?? {};
  return <>
      {isVisibleLayout && !!layout ? layout : <Typography variant="span">{name}</Typography>}
    </>;
};
const matchingClassNames: Record<VariantProps, string> = {
  default: "",
  small: cx(cssSelectSmall),
  smaller: cx(cssSelectSmall, cssSelectSmaller)
};
const Select = memo(forwardRef<ForwardedRef<HTMLInputElement>, SelectPropsType & {
  initialValue?: string;
} & BaseHTMLAttributes<unknown> & Pick<FieldVariantsPropsType, "withButton" | "onClickButton" | "iconButton" | "errorMessage">>(({
  items,
  ariaLabel = "",
  baseId,
  variant,
  isIcon = false,
  onSelectValue,
  initialValue,
  isFetching,
  errorMessage,
  buttonAddedItem,
  withButton,
  onClickButton,
  iconButton,
  iconSelect,
  staticPlaceholder,
  isVisibleLayout = false,
  isVisibleSelectedHint = false,
  required = false,
  classNameWrapper,
  withAnimatingLabel = true,
  className,
  isVisible,
  onClose,
  closeMode = "hide",
  ...props
}, ref) => {
  const [itemSelected, setItemSelected] = useState<SelectItemsType | undefined>();
  const combobox = useComboboxState({
    list: true,
    inline: true,
    autoSelect: false,
    gutter: 2,
    values: (items || []).map(item => item.value),
    unstable_offset: [0, -2],
    placement: "bottom",
    limit: false,
    shift: false,
    visible: isVisible,
    animated: 300,
    baseId
  });
  const previousVisibleRef = useRef(combobox.visible);
  const isEmpty = (itemSelected?.value || "").length <= 0;
  const isShow = closeMode === "hide" || combobox.visible || combobox.animating;
  const onSelectHandle = useCallback((value: string) => {
    combobox.hide();
    combobox.setInputValue("");
    const item = items.find(item => item.value === value);
    setItemSelected(item);
    if (item?.value !== undefined && item.value.length > 0) {
      if (onSelectValue) {
        onSelectValue(item.value);
      }
    }
  }, [combobox, items, onSelectValue]);
  useEffect(() => {
    if (isVisible) {
      combobox.show();
    }
  }, [isVisible]);
  useEffect(() => {
    combobox.setValues(items.map(item => item.value));
  }, [items]);
  useEffect(() => {
    if (!!initialValue && initialValue.length > 0) {
      setItemSelected(items.find(i => i.value === initialValue));
    }
  }, [initialValue, items]);
  useEffect(() => {
    if (!onClose) {
      return;
    }
    if (combobox.visible !== previousVisibleRef.current) {
      if (!combobox.visible) {
        onClose();
      }
    }
    previousVisibleRef.current = combobox.visible;
  }, [combobox.visible]);
  return <StyledFieldWrapper data-animating-label={withAnimatingLabel} data-with-button={withButton} data-is-error={!!errorMessage} data-required={required} className={classNameWrapper}>
          <StyledSelect data-variant={variant} className={cx(matchingClassNames[variant], className)} {...props}>
            {isFetching && <Loader />}

            <StyledSelectInputWrapper>
              <StyledSelectInputContainer data-is-empty={isEmpty}>
                {!!iconSelect && <StyledIconSelect>
                    <Icon NameIcon={iconSelect} />
                  </StyledIconSelect>}
                <Combobox {...combobox} aria-label={ariaLabel || "select"} readOnly onClick={e => {
            if (combobox.visible) {
              e.preventDefault();
              combobox.setVisible(false);
            }
          }} ref={(ref as ForwardedRef<HTMLInputElement>)} />

                {withAnimatingLabel && variant !== "small" && ariaLabel && <Placeholder htmlFor={baseId}>{ariaLabel}</Placeholder>}

                <StyledSelectedTitle>
                  {!!staticPlaceholder && staticPlaceholder}{" "}
                  {renderElement({
              item: itemSelected,
              isVisibleLayout
            })}
                </StyledSelectedTitle>

                {isIcon && !!itemSelected?.icon && <SelectedIcon>
                    <Icon NameIcon={itemSelected.icon} />
                  </SelectedIcon>}

                <Icon NameIcon={"AngleBottom"} className={cssPopoverAngle} />
              </StyledSelectInputContainer>

              {withButton && <Button variant={"box"} icon={iconButton} onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          if (onClickButton) {
            onClickButton(e);
          }
        }} />}
            </StyledSelectInputWrapper>

            {isShow && <ComboboxPopover {...combobox} aria-label={ariaLabel}>
                <StyledSelectListContainer>
                  {!!buttonAddedItem && <StyledButtonContainer>
                      {buttonAddedItem}
                    </StyledButtonContainer>}
                  {combobox.matches.map(value => {
            const item = items.find(item => item.value === value);
            const isSelected = itemSelected?.value === value;
            return <ComboboxOption {...combobox} key={value} value={value} disabled={item?.disabled} onClick={event => {
              event.preventDefault();
              onSelectHandle(value);
            }} className={cx(isSelected && cssIsActive)}>
                        <StyledOptionContainer>
                          {renderElement({
                  item,
                  isVisibleLayout: true
                })}
                          {isIcon && item?.icon && <Icon NameIcon={item.icon} />}
                        </StyledOptionContainer>

                        {isSelected && <Icon NameIcon={"Check"} />}
                      </ComboboxOption>;
          })}
                </StyledSelectListContainer>
              </ComboboxPopover>}
          </StyledSelect>

          {isVisibleSelectedHint && <SelectedHint itemSelected={itemSelected} />}

          {errorMessage && <ErrorMessageField>{errorMessage}</ErrorMessageField>}
        </StyledFieldWrapper>;
}));
Select.displayName = "Select";
export { Select };