import {
  BaseHTMLAttributes,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  memo,
} from "react"
import { LinkItemType } from "@/types"
import { Icon } from "@/ui/Icon"
import { Link } from "@/ui/Link"
import { LinkVariants } from "@/ui/Link/constants"
import { StyledLinkList, StyledLinkListItem } from "./StyledLinkList"

export const LinkListItem: FC<
  Omit<BaseHTMLAttributes<HTMLLIElement>, "title"> & LinkItemType
> = ({ icon, path, title, target, fillIcon, variant, ...props }) => {
  return (
    <StyledLinkListItem {...props}>
      <Link href={path} target={target} variant={variant}>
        {icon && <Icon NameIcon={icon} fill={fillIcon} />}
        {title}
      </Link>
    </StyledLinkListItem>
  )
}

type LinkListPropsType = {
  items: LinkItemType[]
  variant?: LinkVariants
}

const LinkList: FC<
  LinkListPropsType &
    DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>
> = memo(({ items, variant, ...props }) => {
  return (
    <StyledLinkList {...props}>
      {items.map((c, index) => (
        <LinkListItem
          key={index}
          icon={c.icon}
          path={c.path}
          title={c.title}
          fillIcon={c.fillIcon}
          target={c.target}
          variant={variant}
        />
      ))}
    </StyledLinkList>
  )
})
LinkList.displayName = "LinkList"

export default LinkList
