import { FC } from "react"
import {
  PaymentIcons,
  PaymentItem,
  PaymentsGroupTitle,
} from "@/layouts/Default/Footer/StyledFooter"
import { Icon, IconNameType } from "@/ui/Icon"

const icons: IconNameType[] = [
  "PayApple",
  "PayGoogle",
  "PayMastercard",
  "PayMaestro",
  "PayVisa",
  "PayMir",
]

export const Payments: FC = () => {
  return (
    <>
      <PaymentsGroupTitle>Способы оплаты</PaymentsGroupTitle>
      <PaymentIcons>
        {icons.map((i) => (
          <PaymentItem key={i}>
            <Icon NameIcon={i} />
          </PaymentItem>
        ))}
      </PaymentIcons>
    </>
  )
}
